<template>
  <div class="bg">
    <div class="container">
      <div class="auth-wrapper auth-v1">
        <div class="auth-inner">
          <!-- Login v1 -->
          <b-card class="mb-0">
            <div
              v-if="!ag"
              class="LoginForm text-center"
            >
              <img
                src="/warning.png"
                alt=""
                height="80"
                class="mb-2"
              >
              <br>
              <p class="text-white">
                URL ไม่ถูกต้อง กรุณาติดต่อผู้แนะนำ
              </p>
            </div>

            <!-- <button
              v-if="!ag"
              class="cybr-btn mt-1"
              type="submit"
            >
              <span class="g">url ไม่ถูกต้อง กรุณาติดต่อผู้แนะนำ<span aria-hidden>_</span></span>
              <span
                aria-hidden
                class="cybr-btn__glitch"
              >เurl ไม่ถูกต้อง กรุณาติดต่อผู้แนะนำ_</span>
              <span
                aria-hidden
                class="cybr-btn__tag"
              >galaxy7</span>
            </button> -->
            <div
              v-else
              class="LoginForm"
            >
              <div class="text-center">
                <b-img
                  src="/logo/logo.png"
                  alt="logo"
                  height="95"
                />
              </div>
              <!-- form -->
              <validation-observer ref="loginForm">
                <b-form
                  class="auth-login-form"
                  @submit.prevent="login"
                >
                  <!-- email -->

                  <b-form-group>
                    <label>เบอร์โทรศัพท์</label>
                    <validation-provider
                      #default="{ errors }"
                      name="เบอร์โทรศัพท์"
                      vid="email"
                      rules="required"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="11"
                        :state="errors.length > 0 ? false : null"
                        name="login-email"
                        size="lg"
                        placeholder=""
                      />
                      <small class="text-warning">
                        {{ errors[0] ? `กรุณากรอกเบอร์โทรศัพท์` : '' }}
                      </small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">รหัสผ่าน</label>
                      <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                      <small>ลืมรหัสผ่าน?</small>
                    </b-link> -->
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="รหัสผ่าน"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          size="lg"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder=""
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-warning">{{
                        errors[0] ? `กรุณากรอกรหัสผ่านให้ถูกต้อง` : ''
                      }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit buttons -->
                  <!-- <button
                  class="btn-hover color-1"
                  type="submit"
                  block
                >
                  เข้าสู่ระบบ
                </button> -->
                  <button
                    class="btnlogin mt-1"
                    type="submit"
                  >
                    เข้าสู่ระบบ
                  </button>
                  <!-- <b-form-group class="text-center mt-2">
                  <router-link
                    to="/forget-password"
                    class="text-white"
                  >
                    ลืมรหัสผ่าน
                  </router-link>
                </b-form-group> -->
                  <div class="text-center mt-2 my-1">
                    <span
                      style="color: #D2E536"
                    >ต้องการสมัครสมาชิกใช่ไหม?<a
                      :href="`/register-ufa`"
                      style="color: #fff"
                    >
                      สมัครสมาชิก</a></span>
                  </div>

                  <!-- divider -->

                  <!-- Forgot Password -->
                  <!-- <div class="text-center mt-1">
                    <router-link
                      :to="`/forget-password`"
                      class="text-warning"
                    >
                      <p class="mb-0">
                        ลืมรหัสผ่าน
                      </p>
                    </router-link>
                  </div> -->
                </b-form>
              </validation-observer>
              <b-modal
                id="modal-sm"
                cancel-variant="secondary"
                ok-only
                ok-title="ส่งรหัสผ่าน"
                centered
                size="sm"
                title="ลืมรหัสผ่าน"
              >
                <b-input-group
                  class="input-group-merge"
                  title="sss"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="PhoneCallIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="tel"
                    type="number"
                    placeholder="กรุณากรอกเบอร์โทรศัพท์"
                  />
                </b-input-group>
                <small>*กรุณากรอกเบอร์โทรศัพท์ที่ท่านทำการสมัครไว้</small>
              </b-modal>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BImg,
  BForm,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BImg,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      Ip: null,
      // validation rules
      required,
      email,
      ag: 12,
      URLag: this.$route.query.ag,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // console.log(this.$route.query.tel)
    // console.log(this.$route.query.password)
    if (this.$route.query.tel && this.$route.query.password) {
      this.fwlogin(this.$route.query.tel, this.$route.query.password)
    }
  },
  methods: {
    async GetIp() {
      await axios
        .get('https://api.ipify.org')
        .then(response => {
          this.Ip = response.data
        })
        .catch(error => console.log(error))
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              agent_id: this.ag,
              Detail: {
                Ip: this.Ip,
                UserAgent: navigator.userAgent,
                Platform: navigator.platform,
              },
            })
            .then(response => {
              const userData = response.data
              // console.log(response.data)
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('CheckClick', true)
              // localStorage.setItem('token', JSON.stringify(userData).token)
              // localStorage.setItem('userData', JSON.parse(userData))
              this.$ability.update(userData.ability)
              this.$http
                .get('https://api.ufabangkok.com/api/auth/checklogin')
                .then(res => {
                  localStorage.setItem('CountLogin', res.data)
                })

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
              // this.$toast({
              //   component: ToastificationContent,
              //   position: 'top-right',
              //   props: {
              //     title: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
              //     icon: 'LockIcon',
              //     variant: 'danger',
              //     text: error,
              //   },
              // })
            })
        }
      })
    },
    SwalError(mes) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: mes,
          icon: 'LockIcon',
          variant: 'danger',
        },
      })
    },
    fwlogin(tel, pass) {
      useJwt
        .login({
          email: tel,
          password: pass,
          agent_id: this.ag,
          Detail: {
            Ip: this.Ip,
            UserAgent: navigator.userAgent,
            Platform: navigator.platform,
          },
        })
        .then(response => {
          const userData = response.data
          // console.log(response.data)
          useJwt.setToken(response.data.token)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          // localStorage.setItem('token', JSON.stringify(userData).token)
          // localStorage.setItem('userData', JSON.parse(userData))
          this.$ability.update(userData.ability)

          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          this.$router
            .push(getHomeRouteForLoggedInUser(userData.role))
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'เบอร์โทรศัพท์หรือรหัสผ่านไม่ถูกต้อง',
              icon: 'LockIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
label {
  color: #D2E536;
  font-size: 1.2rem;
}
.bg {
  background-image: url(/logo/bg.jpg);
  background-size: cover;
  background-position: center center;
}
.LoginForm {
    width: 100%;
    padding: 11px 30px 20px 30px;
    border-radius: 30px;
    background: #193D61;
}
.btnlogin {
    /* padding: 0.75em; */
    background: #EE7524;
    color: rgb(255, 255, 255);
    border-radius: 18px !important;
    width: 100%;
    height: 43px;
    font-size: 1.3rem;
    border: none;
    text-transform: uppercase;
    font-weight: 400;
    display: inline-block;
    text-align: center;
}
</style>

<style lang="scss" scoped>
.form-control {
  padding: 0.438rem 1rem;
  background: #fff !important;
  background-clip: padding-box;
  border: 0;
  height: 43px;
  border-radius: 18px;
}
.input-group-text {
  padding: 0.438rem 1rem;
  height: 43px;
  margin-bottom: 0;
  text-align: center;
  background: white !important;
  border: 0;
  border-radius: 18px;
}
</style>
